.separator-line {
  border-top: 1px solid black;
  width: 100%;
  margin-top: 1rem;
}

.separator-line.black {
  border-top-color: black;
}

.separator-line.white {
  border-top-color: white;
}