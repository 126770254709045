.with-footer-container {
  min-height: 100%;
  position: relative;
}

.with-footer-content {
  padding-bottom: 8rem;
}

.footer {
  border-top: 1px solid #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4rem;
  padding-bottom: 6rem;
  padding-top: 2rem;
}